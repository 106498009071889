import React from 'react';
import PropTypes from 'prop-types';

import ImageAsBackground from 'components/common/image-as-background';

import './top-section.scss';

function AboutTopSection({ title, description, image, mobileImage }) {
  return (
    <div className="about_top-section container-fluid">
      <h2 className="about_top-section_title">{title}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: description }}
        className="about_top-section_description"
      />
      <ImageAsBackground
        className="about_top-section_image"
        image={image}
        mobileImage={mobileImage}
      />
    </div>
  );
}

AboutTopSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.object.isRequired,
  mobileImage: PropTypes.object,
};

export default AboutTopSection;
