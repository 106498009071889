import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import ImageAsBackground from 'components/common/image-as-background';
import Layout from 'components/common/layout';
import Header from 'components/common/header';
import SubscribeSection from 'components/common/subscribe-section';
import TopSection from 'components/about/top-section';

import 'stylesheets/about/index.scss';

function AboutPage({ data }) {
  const node = (data.allContentfulAboutPage.edges[0] || {}).node || {};

  const { title, foundersSectionTitle, topSections } = node;

  const foundersSectionDescription = (
    (node.foundersSectionDescription || {}).childMarkdownRemark || {}
  ).html;
  const headerImage = (node.headerImage || {}).fluid;
  const mobileHeaderImage = (node.mobileHeaderImage || {}).fluid;

  const whoWeAreImage = data.whoWeAreImage.childImageSharp.fluid;
  const whoWeAreMobileImage = data.whoWeAreMobileImage.childImageSharp.fluid;

  return (
    <Layout title="About">
      <Header styleType="dark" showLogo />
      <main className="about_main main" role="main">
        <div className="about_header-container">
          <div className="about_header-container_overlay" />
          <h1 className="about_title container-fluid">{title}</h1>
          <ImageAsBackground
            image={headerImage}
            mobileImage={mobileHeaderImage}
          />
        </div>

        {topSections.map(
          ({
            id,
            title,
            description,
            backgroundImage,
            mobileBackgroundImage,
          }) => (
            <TopSection
              key={id}
              title={title}
              description={
                ((description || {}).childMarkdownRemark || {}).html || ''
              }
              image={(backgroundImage || {}).fluid}
              mobileImage={(mobileBackgroundImage || {}).fluid}
            />
          ),
        )}

        <div className="about_who-we-are about_top-section container-fluid">
          <h2 className="about_who-we-are_title about_top-section_title">
            {foundersSectionTitle}
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: foundersSectionDescription }}
            className="about_who-we-are_description about_top-section_description"
          />
          <ImageAsBackground
            className="about_top-section_image"
            image={whoWeAreImage}
            mobileImage={whoWeAreMobileImage}
          />
        </div>

        <SubscribeSection />
      </main>
    </Layout>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        whoWeAreImage: file(relativePath: { eq: "who-we-are.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        whoWeAreMobileImage: file(
          relativePath: { eq: "who-we-are-mobile.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allContentfulAboutPage(
          sort: { fields: [publishDate], order: DESC }
          limit: 1
        ) {
          edges {
            node {
              title
              foundersSectionTitle
              topSections {
                id
                title
                description {
                  childMarkdownRemark {
                    html
                  }
                }
                backgroundImage {
                  fluid {
                    ...GatsbyContentfulFluid
                  }
                }
                mobileBackgroundImage {
                  fluid {
                    ...GatsbyContentfulFluid
                  }
                }
              }
              foundersSectionDescription {
                childMarkdownRemark {
                  html
                }
              }
              headerImage {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              mobileHeaderImage {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => <AboutPage data={data} {...props} />}
  />
);
